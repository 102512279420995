import "./App.css";

function App() {
  return (
    <>
      <head>
        <meta charset="utf-8"></meta>
        <title>Coming Soon Page Design</title>
        <link rel="stylesheet" type="text/css" href="style.css" />
        <link
          href="https://fonts.googleapis.com/css?family=Fira+Sans"
          rel="stylesheet"
        />
      </head>
      <body>
        <header>
          <div class="Soon">
            <p>Manlytic Group</p>
            <p id="tagline">
              Manpower Resources & Contracting Staffing Specialists
            </p>
            <hr />
            <h1>COMING SOON</h1>
            <hr />
            <p id="launch">Please contact us on:</p>
            <p id="launchh">admin@manlytic.org</p>
            <p id="launchh">+971503594183</p>
          </div>
        </header>
      </body>
    </>
  );
}

export default App;
